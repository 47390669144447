import { Injectable, inject } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { Subscription, firstValueFrom } from 'rxjs';
import { PortfolioService } from '@app/core/services/client/portfolio.service';
import { IPortfolio } from './portfolio.model';
import { PortfolioActions } from './portfolio.action';
import { AuthSelectors } from '@app/auth/store/auth.selectors';



@State<IPortfolio>({
    name: 'portfolio',
    defaults: {
        ngn: {
            ngnPortfolioBalance: 0,
            usdPortfolioBalance: 0,
            walletBalance: 0,
            stats: {
                mutual_funds: 0,
                lcm_sum: 0,
                pms_sum: 0,
                cashOnHand: 0
            }
        },
        usd: {
            ngnPortfolioBalance: 0,
            usdPortfolioBalance: 0,
            walletBalance: 0,
            stats: {
                mutual_funds: 0,
                lcm_sum: 0,
                pms_sum: 0,
                cashOnHand: 0
            }
        }
    },
})
@Injectable()
export class PortfolioState {
    subs: Subscription[] = [];
    service = inject(PortfolioService);
    store = inject(Store);


    // Helper method to fetch and format accounts
    @Action(PortfolioActions.GetNGNStats)
    async GetNGNStats(ctx: StateContext<IPortfolio>) {
        try {
            const activeAccount = this.store.selectSnapshot(AuthSelectors.getActiveAccount);
            console.log('activeAccount', activeAccount);
            if (!activeAccount) {
                return;
            }
            const data = await firstValueFrom(
                this.service.getPortfolioStat(activeAccount?.id ?? '', "NGN")
            );
            ctx.setState({
                ...ctx.getState(),
                ngn: {
                    ...ctx.getState().ngn,
                    stats: data.data
                }
            });

        } catch (error) {
            console.error('Error fetching ngn portfolio:', error);
            throw error;
        }
    }

    @Action(PortfolioActions.GetNGNStats)
    async GetUSDStats(ctx: StateContext<IPortfolio>) {
        try {
            const activeAccount = this.store.selectSnapshot(AuthSelectors.getActiveAccount);
            if (!activeAccount) {
                return;
            }
            const data = await firstValueFrom(
                this.service.getPortfolioStat(activeAccount?.id ?? '', "USD")
            );
            ctx.setState({
                ...ctx.getState(),
                usd: {
                    ...ctx.getState().usd,
                    stats: data.data
                }
            });

        } catch (error) {
            console.error('Error fetching usd portfolio:', error);
            throw error;
        }
    }

}
