const ACTION_SCOPE = '[PORTFOLIO]';

export namespace PortfolioActions {
    export class GetNGNStats {
        static readonly type = `${ACTION_SCOPE} Get Portfolio Stats`;
        constructor() { }
    }

    export class GetUSDStats {
        static readonly type = `${ACTION_SCOPE} Get Portfolio Stats`;
        constructor() { }
    }
}